const copy = (value, event) => {
  const el = document.createElement('input')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  event.stopPropagation()
}

const copyFormattedText = (value, event) => {
  const el = document.createElement('textarea')
  el.value = value
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  event.stopPropagation()
}

export default {
  copy, copyFormattedText
}
