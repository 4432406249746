<template>
  <div class="abstract-data-table__td" :style="columnStyle">
    <slot name="before" />
    <span v-if="titleHeader" class="abstract-data-table__td-desc">{{ titleHeader }}</span>
    <span :class="{
      'abstract-data-table__td-title': true,
      'abstract-data-table__td-title--subtitle': subtitle
    }">
      {{ titleValue }}
    </span>
    <slot name="after" />
  </div>
</template>

<script>
export default {
  name: 'TitleColumn',
  props: {
    titleHeader: {
      type: String,
      required: true
    },
    titleValue: {
      type: [String, Number],
      default: ''
    },
    subtitle: {
      type: Boolean,
      default: false
    },
    columnStyle: {
      type: String,
      default: ''
    }
  }
}
</script>
