<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="sport24seasons"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    table-css-class="sport-season-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.nationName"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.fortunaSportName"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.fortunaCatName"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.competitionName"
      />
      <TitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.year"
      />
    </template>
    <template #actions="{ item }">
      <ButtonTableAction icon="info" @click="infoButtonClick(item)" />
      <BtnCopyToClipboard
        :data="item.id"
        :notify-message="$t('notify.id_was_copied')"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'

export default {
  name: 'SportSeasonTable',
  props: {
    sport24seasons: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    ButtonTableAction,
    BtnCopyToClipboard,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('sport24.list.id') },
        { name: this.$t('sport24.list.nationName') },
        { name: this.$t('sport24.list.fortunaSportName') },
        { name: this.$t('sport24.list.fortunaCatName') },
        { name: this.$t('sport24.list.competitionName') },
        { name: this.$t('sport24.list.year') },
        { name: this.$t('actions') }
      ]
    }
  },
  methods: {
    infoButtonClick (item) {
      this.$router.push(`${this.$router.currentRoute.path}/${item.id}`)
    }
  }
}
</script>
<style lang="scss">
  .sport-season-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) rem(130px) rem(130px) rem(130px) rem(130px) rem(130px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) rem(160px) rem(160px) rem(160px) rem(160px) rem(160px) rem(138px);
      }
    }
  }
</style>
