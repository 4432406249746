<template>
  <AbstractFilter
    :on-filter="filterSport24Season"
    :on-filter-reset="resetFilter"
    :show-create-button="false"
    filter-panel-class="filter-panel-sport-season"
  >
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('sport24.filter.id')"
    />
    <Input
      v-model="filter.nationName"
      id="filter_nationName"
      :label="$t('sport24.filter.nationName')"
    />
    <Input
      v-model="filter.fortunaSportName"
      id="filter_fortunaSportName"
      :label="$t('sport24.filter.fortunaSportName')"
    />
    <Input
      v-model="filter.fortunaCatName"
      id="filter_fortunaCatName"
      :label="$t('sport24.filter.fortunaCatName')"
    />
    <Input
      v-model="filter.competitionName"
      id="filter_competitionName"
      :label="$t('sport24.filter.competitionName')"
    />
    <Input
      v-model="filter.year"
      id="filter_year"
      :label="$t('sport24.filter.year')"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import SportSeasonFilterModel from '@/model/SportSeasonFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'

export default {
  name: 'SportSeasonFilter',
  data () {
    return {
      filter: this._.cloneDeep(SportSeasonFilterModel)
    }
  },
  components: {
    AbstractFilter,
    Input
  },
  methods: {
    filterSport24Season () {
      this.$store.commit('sport24season/setPage', 1)
      this.$store.commit('sport24season/setFilter', this.filter)
      this.$store.dispatch('sport24season/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(SportSeasonFilterModel)
      this.$store.commit('sport24season/setFilter', this.filter)
      this.$store.dispatch('sport24season/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['sport24season/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-sport-season {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
