<template>
  <section>
    <SportSeasonFilter />
    <Preloader v-if="callingAPI" />
    <SportSeasonTable
      :sport24seasons="sport24seasons"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import SportSeasonFilter from '@/components/filter/FilterSportSeason'
import SportSeasonTable from '@/components/table/SportSeasonTable'

export default {
  name: 'SportSeasonListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    sport24seasons () {
      return this.$store.getters['sport24season/list']
    },
    totalCount () {
      return this.$store.getters['sport24season/totalCount']
    },
    page () {
      return this.$store.getters['sport24season/page']
    }
  },
  components: {
    SportSeasonTable,
    Preloader,
    SportSeasonFilter
  },
  methods: {
    getSport24Seasons () {
      this.$store.dispatch('sport24season/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('sport24season/setPage', page)
      this.getSport24Seasons()
    }
  },
  mounted () {
    this.getSport24Seasons()
  }
}
</script>
